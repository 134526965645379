import * as Inputmask from "inputmask";
import {ErrorHandler} from "../../modules/ErrorHandler";
import {Utils} from "../Utils";
import {Pages} from "./Pages";

export class GroupsEdit extends Pages{

    initPageHandlers(params) {
        super.initPageHandlers(params);
        let $name = $("#group_name"),
            $code = $("#group_code"),
            $course = $("#group_course"),
            $dateStart = $("#group_date_start"),
            $dateEnd = $("#group_date_end"),
            $dateStartSite = $("#group_date_start_site"),
            $scheduleType = $("#group_schedule_type"),
            $required = $(':input[required]:visible'),
            timeFormat = "H:i",
            dateFormat = app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
            im = new Inputmask("99:99", {
                insertMode: false,
                showMaskOnHover: false,
                alias: "datetime",
                hourFormat: 24
            }),
            $timeInputs = $(".timemask"),
            timeInputWrong = Utils.timeInputWrong,
            errorHandler = new ErrorHandler();

        $dateStart
            .datepicker({
                dateFormat: dateFormat,
                defaultDate: "0",
                changeMonth: true,
                changeYear: true
            })
            .on("change", function () {
                $dateEnd.datepicker("option", "minDate", getDate(this));
                if (null === $dateStartSite.datepicker("getDate")) {
                    $dateStartSite.datepicker("setDate", getDate(this));
                }
            });
        $dateEnd
            .datepicker({
                dateFormat: dateFormat,
                defaultDate: "0",
                changeMonth: true,
                changeYear: true
            });
        $dateStartSite
            .datepicker({
                dateFormat: dateFormat,
                defaultDate: "0",
                changeMonth: true,
                changeYear: true
            });

        im.mask($timeInputs);


        function getDate(element) {
            let date;
            try {
                date = $.datepicker.parseDate(dateFormat, element.value);
            } catch (error) {
                date = null;
            }

            return date;
        }

        //валидация полей ввода времени
        $timeInputs.blur(function (e) {
            let $el = $(this),
                time = $el.val();
            if (time !== "" && timeInputWrong(time)) {
                $el
                    .addClass("red_background")
                    .focus()
                    .select();
            } else {
                $el
                    .removeClass("red_background");
            }
        });


        //валидация (совсем небольшая)
        $(":submit").on("click touchstart", function () {

            let $form = $("form");

            errorHandler.checkErrorDataFields($form);

            if ($code.val() === "") {
                errorHandler.setErrorOnField($code, "Укажите код группы!");
            } else {
                errorHandler.uncheckErrorOnField($code);
            }

            if ($name.val() === "") {
                errorHandler.setErrorOnField($name, "Укажите название группы!");
            } else {
                errorHandler.uncheckErrorOnField($name);
            }

            if ($course.val() === "0") {
                errorHandler.setErrorOnField($course, "Укажите курс для группы!");
            } else {
                errorHandler.uncheckErrorOnField($course);
            }

            if ($scheduleType.val() === "0") {
                errorHandler.setErrorOnField($scheduleType, "Укажите тип расписания для группы!");
            } else {
                errorHandler.uncheckErrorOnField($scheduleType);
            }

            if ($dateStart.val() === "") {
                errorHandler.setErrorOnField($dateStart, "Дата запуска группы должна быть указана!");
            } else {
                errorHandler.uncheckErrorOnField($dateStart);
            }

            if ($dateEnd.val() === "") {
                errorHandler.setErrorOnField($dateEnd, "Дата окончания группы должна быть указана!");
            } else {
                errorHandler.uncheckErrorOnField($dateEnd);
            }

            let hasTimeInputError = false;
            $timeInputs.each(function (index) {
                let time = $(this).val();
                if (time !== "" && timeInputWrong(time)) {
                    hasTimeInputError = true;
                    errorHandler.setErrorOnField($(this), "Неверно указано время в одном из пунктов расписания!");
                    return false;
                }
            });

            if (!hasTimeInputError) {
                errorHandler.uncheckErrorOnField($timeInputs);
            }


            if (errorHandler.hasError) {
                errorHandler.showErrors(
                    $form,
                    "Неверно заполнены данные формы!",
                    "Данные не могут быть сохранены!"
                );
                //обработчик на поля, требующие заполнения
                $required.change(function (e) {
                    let $field = $(e.target);
                    errorHandler.uncheckErrorOnField($field);
                });
                return false;
            }

        });
    }

}

/**
 *
 * @constructor ErrorHandler
 */
export function ErrorHandler() {

    'use strict';

    this.options = {
        dataErrorName: "error",
        dataErrorMessageName: "error-message",
        errorClass: "red_background",
        defaultMessage: "ошибка заполнения поля"
    };
    this.hasError = false;
    this.errorMessages = [];
}

ErrorHandler.prototype.setErrorAttr = function ($el, hasError, message) {
    'use strict';
    $el
        .data(this.options.dataErrorName, hasError)
        .data(this.options.dataErrorMessageName, message);
};

ErrorHandler.prototype.checkFieldOnDataError = function ($el) {
    'use strict';
    return $el.data(this.options.dataErrorName);
};

ErrorHandler.prototype.checkErrorDataFields = function ($form) {
    'use strict';
    let _this = this;
    //проверим все поля с аттрибутом error
    $form.find("input").each(function (i, el) {
        let $element = $(el);
        if (_this.checkFieldOnDataError($element)) {
            _this.setErrorOnField($element);
        } else {
            _this.uncheckErrorOnField($element);
        }
    });
};

ErrorHandler.prototype.uncheckErrorOnField = function ($el) {
    'use strict';
    $el.removeClass(this.options.errorClass);
};

ErrorHandler.prototype.getErrorMessage = function ($el) {
    'use strict';
    return $el.data(this.options.dataErrorMessageName);
};

ErrorHandler.prototype.getElementName = function ($el) {
    'use strict';
    var label = $("label[for='" + $el.attr('id') + "']"),
        name = $el.attr('name'),
        id = $el.attr('id');
    if (label !== "") {
        return label;
    } else if (name !== "") {
        return name;
    } else if (id !== "") {
        return name;
    }
    return "";
};

ErrorHandler.prototype.setErrorOnField = function ($el, mes) {
    'use strict';
    let storedMes = mes || this.getErrorMessage($el),
        defaultMes = this.options.defaultMessage + " " + this.getElementName;
    if (storedMes !== "") {
        defaultMes = storedMes;
    }
    $el.addClass(this.options.errorClass);
    this.errorMessages.push(defaultMes);
    this.hasError = true;
};

/**
 * показывает ошибки формы, добавляя сообщения к контейнеру
 * @param {jQuery} $container JQUERY object
 * @param {string} header
 * @param {string} finalMessage
 */
ErrorHandler.prototype.showErrors = function ($container, header, finalMessage) {
    'use strict';

    var errorContainerID = "errorContainer",
        errorContainerSelector = $("#" + errorContainerID),
        $errorContainer = $("<div id='" + errorContainerID + "'></div>"),
        message;

    //удалим сообщение, если оно уже отображается, чтобы показать новое
    errorContainerSelector.remove();

    message = "<h5>" + header + "</h5>";
    message += this.errorMessages.join("<br>");
    message += "<p class='fw-bold pt-3'>" + finalMessage + "</p>";

    $errorContainer
        .insertBefore($container)
        .addClass("alert alert-danger")
        .html(message)
        .show();
    //обнулим переменные
    this.hasError = null;
    this.errorMessages = [];
};
